@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add any global styles below this line */
body {
  font-family: 'Inter', sans-serif;
  background-color: white;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

